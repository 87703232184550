import {Modal} from "react-bootstrap";
import {lf} from "../DSYNR/Utils/debug";
import {useDispatch} from "react-redux";
import {blurMainScreen, focusMainScreen, update} from "../redux/actions/ACTIONS_APP";

export default function AModal(props) {
    lf('AModal', props)

    const dispatch = useDispatch()
    const useOverlay = props.overlay
    const isBgStatic = props.static ? 'static' : useOverlay

    return (
        <Modal className="border-0"
               show={props.isVisible}
               onHide={() => {
                   if (props.name && props.isClosable) {
                       dispatch(update(props.name, {isNewVisible: false}))
                   }
               }}
               centered
               size="lg"
               backdrop={isBgStatic}
        >
            {props.header ? (
                <Modal.Header className="border-0" closeButton={props.isClosable}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.header}
                    </Modal.Title>
                </Modal.Header>
            ) : ""}

            <Modal.Body className="p-0">
                {props.body}

                {props.footer ? (
                    <Modal.Footer>
                        {props.footer}
                    </Modal.Footer>
                ) : ""}
            </Modal.Body>
        </Modal>
    );
}
