import {lf} from "../../DSYNR/Utils/debug";
import {Col, Row} from "react-bootstrap";
import {Greeting} from "../../comps/Greeting";
import React from "react";
import {useSelector} from "react-redux";

export default function DashboardHeader() {
    lf('DashboardHeader')

    const fname = useSelector(state => state.app.profile.fname)
    const dashboardTitle = 'This is the Dashboard of ' + useSelector(state => state.app.account.name)

    return <Row className="py-5">
        <Col>
            <h1 className='display-1 fw-bold'><Greeting name={fname}/></h1>
            <p className="lead text-muted">{dashboardTitle}</p>
        </Col>
    </Row>
}
