import {FORM_CONTROLS, props_FControl} from "../_state";
import AControl from "../FormControl";
import React from "react";

export const getControl_TextArea = (formID, secID, controlName, label = '', value = '', isRequired = false, placeholder = '', hint = '', errorTip = 'Eh!?', isVisible = true) => {
    return <AControl formID={formID} secID={secID}
                     {...props_FControl(controlName, FORM_CONTROLS.textarea, value, label, isRequired, placeholder, hint, errorTip, isVisible)}/>
}

export const getControl_Text = (formID, secID, controlName, label = '', value = '', isRequired = false, placeholder = '', hint = '', errorTip = 'Eh!?', isVisible = true, type = FORM_CONTROLS.text, hideFieldValidIcon = false, conjoinLabel = false) => {
    return <AControl formID={formID} secID={secID}
                     {...props_FControl(controlName, type, value, label, isRequired, placeholder, hint, errorTip, isVisible, hideFieldValidIcon, conjoinLabel)}/>
}
export const getControl_Password = (formID, secID, controlName, label = '', isRequired = false, placeholder = '', hint = '', errorTip = 'Eh!?', isVisible = true) => {
    return <AControl formID={formID} secID={secID}
                     {...props_FControl(controlName, FORM_CONTROLS.password, '', label, isRequired, placeholder, hint, errorTip, isVisible)}/>
}

export const getControl_Checkbox = (formID, secID, controlName, label = '', checked = true, isRequired = false, hint = '', errorTip = 'Eh!?', isVisible = true, type = FORM_CONTROLS.checkbox) => {
    return <AControl formID={formID} secID={secID}
                     {...props_FControl(controlName, type, checked, label, isRequired, '', hint, errorTip, isVisible)}/>
}

export const getControl_Calendar = (formID, secID, controlName, initialDate) => {
    return <AControl formID={formID} secID={secID}
                     {...props_FControl(controlName, FORM_CONTROLS.calendar, initialDate)} />
}

export const getControl_Time = (formID, secID, controlName, initialTime) => {
    return <AControl formID={formID} secID={secID}
                     {...props_FControl(controlName, FORM_CONTROLS.time, initialTime)} />
}

export const getControl_Email = (formID, secID, controlName, label = '', value = '', isRequired = false, placeholder = '', hint = '', errorTip = 'Eh!?', isVisible = true, hideFieldValidIcon = false, conjoinLabel = false) => {
    return getControl_Text(formID, secID, controlName, label, value, isRequired, placeholder, hint, errorTip, isVisible, FORM_CONTROLS.email, hideFieldValidIcon, conjoinLabel)
}

export const getControl_Number = (formID, secID, controlName, label = '', value = '', isRequired = false, placeholder = '', hint = '', errorTip = 'Eh!?', isVisible = true, hideFieldValidIcon = false, conjoinLabel = false) => {
    return getControl_Text(formID, secID, controlName, label, value, isRequired, placeholder, hint, errorTip, isVisible, FORM_CONTROLS.number, hideFieldValidIcon, conjoinLabel)
}

export const getControl_Switch = (formID, secID, controlName, label = '', checked = true, isRequired = false, hint = '', errorTip = 'Eh!?', isVisible = true) => {
    return getControl_Checkbox(formID, secID, controlName, label, checked, isRequired, hint, errorTip, isVisible, FORM_CONTROLS.switch)
}