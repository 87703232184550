import {Carousel} from "react-bootstrap";
import React from "react";
import {lf} from "../Utils/debug";
import {FormWizardSlides} from "./FormWizardSlides";
import FormWizardController from "./FormWizardController";

export default function FormWizard(props) {

    lf('FormWizard', props)

    const id = props.id
    const activeIndex = props.wizard.activeIndex
    const maxIndex = props.wizard.maxIndex

    return <>
        <Carousel
            activeIndex={activeIndex}
            controls={false} indicators={false}
            keyboard={false} interval={null} wrap={false}
            className="wizard"
            onSlide={() => {
                props.updateWizardControls(props)
            }}
        >
            {FormWizardSlides(props)}
        </Carousel>
        {FormWizardController(props)}
    </>
}
