import {lf} from "../../DSYNR/Utils/debug";
import React from "react";
import DashboardWidgets from "./DashboardWidgets";
import DashboardHeader from "./DashboardHeader";
import {Container} from "react-bootstrap";

export default function Dashboard() {

    lf('Dashboard');

    return <Container>
        <DashboardHeader/>
        <DashboardWidgets/>
    </Container>
}
