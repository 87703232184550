import {CarouselItem} from "react-bootstrap";
import React from "react";
import {lf} from "../Utils/debug";
import ErrorTip from "./FormError";

export function FormSection(props) {
    lf('FormSection', props)

    const id = props.id
    const formSection = props.formSection
    const sectionID = formSection.id

    const isValid = formSection.isValid
    const errorTip = formSection.errorTip
    const errorHints = formSection.errorHints

    //@todo: hide PrevBtn for first slide upon initial load
    return <CarouselItem key={props.index}>

        <fieldset id={sectionID} className="formFieldsGroup">

            {!isValid ? <ErrorTip hints={errorHints} tip={errorTip} type="section"/> : ""}

            {formSection.body}

        </fieldset>

    </CarouselItem>
}
