import {lf} from "../../DSYNR/Utils/debug";
import {Button, Col, Container, Row} from "react-bootstrap";
import {ImBackward, ImForward, TiArrowBack} from "react-icons/all";
import {useDispatch, useSelector} from "react-redux";
import {changeScreen, hideMenuPanel, hideNotificationPanel} from "../../redux/actions/ACTIONS_APP";
import React from "react";
import GoToWidget from "../../widgets/GoToWidget";
import OverviewWidget from "../../widgets/OverviewWidget";
import SearchForm from "../../forms/SearchForm";

export default function MenuPanel() {
    lf('MenuPanel');

    const dispatch = useDispatch()

    const isMenuVisible = useSelector(state => state.app.isMenuVisible)
    const isPanelVisibleCls = isMenuVisible ? "isVisible" : ""


    const panelStyles = 'bg-gradient bg-light position-absolute top mt-5 pt-5 h-100 shadow-lg sidePanel ' + isPanelVisibleCls

    return <section id="menuPanel" className={panelStyles}>

        <Button variant={"secondary"}
                onClick={() => {
                    dispatch(hideMenuPanel())
                }} className="text-white me-3 float-end">
            <TiArrowBack/>
        </Button>

        <Container className='bg-light bg-gradient p-5'>

            <Row>
                <Col>
                    <SearchForm/>
                    <hr className='bg-white my-5'/>
                    <OverviewWidget/>
                </Col>
            </Row>
        </Container>

        <Button size='lg' className='d-table mx-auto' onClick={() => {
            dispatch(changeScreen('dashboard'))
            dispatch(hideMenuPanel())
        }}>Go to Dashboard</Button>


    </section>
}
