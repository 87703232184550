import {lf} from "../../Utils/debug";
import {useDispatch} from "react-redux";
import {updateControlVal} from "../_actions";
import DatePicker from "react-datepicker";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";

export default function Calendar(controlProps) {
    lf('Calendar', controlProps)
    const dispatch = useDispatch()

    const changeDate = (date) => {
        controlProps.focusControl()
        dispatch(updateControlVal(controlProps.name, date.toDateString(), controlProps.secID, controlProps.formID))
    }

    const initialDate = new Date(controlProps.value)

    return <DatePicker
        selected={initialDate}
        onClickOutside={controlProps.blurControl}
        openToDate={initialDate}
        onChange={changeDate}
        allowSameDay={false}
        inline
    />
}
