import {lf} from "../../DSYNR/Utils/debug";
import {Button, Col, Container, Row} from "react-bootstrap";
import {ImForward} from "react-icons/all";
import {useDispatch, useSelector} from "react-redux";
import {changeScreen, hideMenuPanel, hideNotificationPanel} from "../../redux/actions/ACTIONS_APP";
import NotificationSlot from "./NotificationSlot";
import ProfileWidget from "../../widgets/ProfileWidget";
import React from "react";
import AccountWidget from "../../widgets/AccountWidget";
import GoToWidget from "../../widgets/GoToWidget";

export default function NotificationPanel() {
    lf('NotificationPanel');

    const dispatch = useDispatch()

    const notificationPanel = useSelector(state => state.app.notification)
    const isPanelVisible = notificationPanel.isPanelVisible
    const isPanelVisibleCls = isPanelVisible ? "isVisible" : ""


    const panelStyles = 'bg-gradient bg-light position-absolute top mt-5 pt-5 h-100 shadow-lg sidePanel ' + isPanelVisibleCls

    return <section id="notificationPanel" className={panelStyles}>

        <Button variant={"secondary"}
                onClick={() => {
                    dispatch(hideNotificationPanel())
                }} className="text-white me-3 float-end">
            <ImForward/>
        </Button>

        <NotificationSlot/>

        <Container className='bg-light bg-gradient border-top p-5'>

            <Row>
                <Col>
                    <GoToWidget/>
                </Col>
            </Row>
        </Container>

        <Button size='lg' className='d-table mx-auto' onClick={() => {
            dispatch(changeScreen('dashboard'))
            dispatch(hideMenuPanel())
        }}>Go to Dashboard</Button>


    </section>
}
