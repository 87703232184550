import {combineReducers} from "redux";
import {connectRouter} from 'connected-react-router';
import REDUCER_APP from "./REDUCER_APP";
import REDUCER_Forms from "../../DSYNR/Form/_reducers";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    forms: REDUCER_Forms,
    app: REDUCER_APP,
})
export default createRootReducer
