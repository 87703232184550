import {connect, useSelector} from "react-redux";
import {FormGroup, FormLabel, FormText} from "react-bootstrap";
import {
    blurControl,
    focusControl,
    hideInput,
    showInput,
    validateControl,
    neutraliseControl, focusSection, focusForm
} from "./_actions";
import {lf} from "../Utils/debug";
import ErrorTip from "./FormError";
import React from "react";
import {FORM_CONTROLS} from "./_state";
import {updateObj} from "../Utils/obj";
import Input from "./_controls/Input";
import Textarea from "./_controls/Textarea";
import Select from "./_controls/Select";
import Calendar from "./_controls/Calendar";
import Time from "./_controls/Time";
import Check from "./_controls/Check";

/**
 * Bootstrap form control - input
 */
function AControl(props) {
    lf('AControl', props)

    const formID = props.formID
    const secID = props.secID

    const control = useSelector(state => state.forms[formID].sections[secID].controls[props.name])

    const name = control.name
    const label = control.label
    const hint = control.hint
    const type = control.type
    const isRequired = control.isRequired
    const isVisible = control.isVisible
    const isValid = control.isValid
    const isInvalid = control.isInvalid
    const value = control.value
    const placeholder = control.placeholder
    const size = control.size
    const isPlaintext = control.isPlaintext
    const isReadOnly = control.isReadOnly
    const hideFieldValidIcon = control.hideFieldValidIcon
    const conjoinLabel = control.conjoinLabel

    const showError = control.showError
    const errorTip = control.errorTip

    const controlProps = updateObj(props, {...props, ...control})

    let formControl
    let showInlineLabel = false //true for checkboxes and radios only


    switch (type) {
        case FORM_CONTROLS.textarea:
            formControl = Textarea(controlProps)
            break
        case FORM_CONTROLS.select:
            formControl = Select(controlProps)
            break
        case FORM_CONTROLS.calendar:
            formControl = Calendar(controlProps)
            break
        case FORM_CONTROLS.time:
            formControl = Time(controlProps)
            break
        case FORM_CONTROLS.checkbox:
        case FORM_CONTROLS.switch:
        case FORM_CONTROLS.radio: //@todo not yet properly implemented, don't use
            showInlineLabel = true
            formControl = Check(controlProps)
            break
        default:
            //text, email, number
            formControl = Input(controlProps)
            break
    }

    let visibilityCls = isVisible ? "" : "d-none"
    const FormGroupCls = "px-1 " + visibilityCls

    return <FormGroup
        controlId={name}
        className={FormGroupCls}>

        {!showInlineLabel && !conjoinLabel ? (<FormLabel>{label}</FormLabel>) : ""}

        {formControl}

        <FormText>

            {showError ? <ErrorTip tip={errorTip}/> : ""}

            <span className="ml-1 text-muted">{hint}</span>
        </FormText>

    </FormGroup>
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const name = ownProps.name
    return {
        // registerControl: () => dispatch(registerControl(name, ownProps)),
        showInput: () => dispatch(showInput(name)),
        hideInput: () => dispatch(hideInput(name)),
        focusControl: () => {
            dispatch(focusForm(ownProps.formID))
            dispatch(focusSection(ownProps.secID))
            dispatch(focusControl(name, ownProps.secID, ownProps.formID))
            dispatch(neutraliseControl(name, ownProps.secID, ownProps.formID))
        },
        blurControl: () => {
            dispatch(blurControl(name, ownProps.secID, ownProps.formID))
            if (!ownProps.hideFieldValidIcon) {
                dispatch(validateControl(name, ownProps.secID, ownProps.formID))
            }
        },
    }
}

export default connect(
    null,
    mapDispatchToProps,
)(AControl)
