import {HISTORY} from "../__STORE";
import thunkMiddleware from 'redux-thunk'
import {applyMiddleware, compose, createStore} from 'redux'
import loggerMiddleware from './middleware/logger'
import {routerMiddleware} from 'connected-react-router'
import createRootReducer from "./reducers";
// import monitorReducersEnhancer from './enhancers/monitorReducer'

export default function configureStore(preloadedState) {
    const middlewares = [thunkMiddleware, routerMiddleware(HISTORY), loggerMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)

    //https://stackoverflow.com/questions/57628279/composeenhancers-is-not-a-function-in-reactjs
    // const reduxDevToolsExt = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
    // const enhancers = [middlewareEnhancer, monitorReducersEnhancer, reduxDevToolsExt]

    const enhancers = [middlewareEnhancer]

    const composedEnhancers = compose(...enhancers)

    const store = createStore(
        createRootReducer(HISTORY),
        preloadedState,
        composedEnhancers,
    )
    return store
}
