import {lf} from "../DSYNR/Utils/debug";
import React from "react";
import {Button, Card, ListGroup} from "react-bootstrap";
import {BsThreeDots} from "react-icons/all";
import {useSelector} from "react-redux";
import ASpinner from "../comps/ASpinner";

export default function ProfileWidget() {
    lf('ProfileWidget')

    const icoAccount = useSelector(state => state.app.account.ico)
    const account = useSelector(state => state.app.account)

    const summary = <>
        <Card.Title>{account.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{account.timezone}</Card.Subtitle>
        <Card.Text>
            {account.companyDetails}
        </Card.Text>
        <Button variant={"light"} className="fs-4"><BsThreeDots/></Button>
    </>

    return <>
        <h3 className="fw-lighter display-6 opacity-75 text-secondary mb-4">
            <span className="text-secondary opacity-25">{icoAccount}</span> Account
        </h3>
        <Card className="border-0 rounded shadow-sm">
            <Card.Body>
                {summary || <ASpinner/>}
            </Card.Body>
        </Card>
    </>
}
