import bg from '../img/bg-signin.jpg'
import React from "react";
import SignInForm from "../forms/SignInForm";
import AModal from "../comps/AModal";
import {lf} from "../DSYNR/Utils/debug";

export default function SignInScreen() {
    lf('SignInScreen')

    return <>
        <main className="w-100" style={{height: '100vh', backgroundImage: `url(${bg})`, backgroundSize:'cover'}}>
            <AModal static={true} isVisible={true} body={<SignInForm/>}/>
        </main>
    </>
}
