import {Button, Form} from "react-bootstrap";
import React from "react";
import ErrorTip from "./FormError"

export default function _Form(props, body) {

    let showSubmit = props.showSubmitBtn ? "d-table" : "d-none"

    let formStyles = props.skipFormStyling ? '' : 'p-3 p-sm-4 p-md-5 rounded-lg bg-light'

    return <Form
        className={formStyles}
        id={props.id}
        onFocus={props.focusForm}
        onBlur={props.blurForm}
    >
        {props.header}

        {!props.isValid ? <ErrorTip tip={props.errorTip} type="form"/> : ""}

        {body}

        <div className={`${showSubmit} mx-auto my-5`}>
            <Button type="submit" size="lg"
                    onClick={e => {
                        e.preventDefault()
                        props.submitForm(props)
                    }}
            >{props.submitBtnTxt}</Button>
        </div>

        {props.footer}

    </Form>
}
