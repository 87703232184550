const INITIAL_STATE_FORMS = {
    currentForm: {
        id: null,
        secID: null,
        data: null,
    }
}
export default INITIAL_STATE_FORMS

/**
 *
 * @type {{calendar: string, number: string, password: string, select: string, checkbox: string, textarea: string, text: string, time: string, email: string, switch: string, radio: string}}
 */
export const FORM_CONTROLS = {
    text: 'text',
    email: 'email',
    number: 'number',
    textarea: 'textarea',
    select: 'select',
    checkbox: 'checkbox',
    switch: 'switch',
    radio: 'radio',//@todo
    calendar: 'calendar',
    time: 'time',
    password: 'password',
}

/**
 *
 * @param formID
 * @param sections
 * @param token
 * @param header
 * @param footer
 * @param submitBtnTxt
 * @param errorTip
 * @param showSubmitBtn
 * @param skipFormStyling
 * @param sendAllData If false will send the data only from the fields whose values have been changed from the defaults
 * @returns {{footer: null, errorTip: string, isValid: boolean, header: null, showSubmitBtn: boolean, wizard: {activeIndex: number, maxIndex: number, showNextBtn: boolean, showPrevBtn: boolean}, id, submitBtnTxt: string, sections}}
 */
export const props_Form = (formID, sections, token = false, header = null, footer = null, submitBtnTxt = 'Submit Form', errorTip = 'Something went wrong! Try again?', showSubmitBtn = true, skipFormStyling = false, sendAllData = true) => {
    return {
        id: formID,
        token: token,
        sections: sections,
        header: header,
        footer: footer,
        wizard: {
            activeIndex: 0,
            maxIndex: 0,
            showPrevBtn: true,
            showNextBtn: true,
        },
        showSubmitBtn: showSubmitBtn,
        submitBtnTxt: submitBtnTxt,
        isValid: true,
        errorTip: errorTip,
        skipFormStyling: skipFormStyling,
        sendAllData: sendAllData,
    }
}

/**
 *
 * @param groupID
 * @param body
 * @param showNextBtn
 * @param showPrevBtn
 * @param errorTip
 * @returns {{controls: {}, errorTip: string, showNextBtn: boolean, showPrevBtn: boolean, isValid: boolean, errorHints: *[], id, body}}
 */
export const props_FSection = (groupID, body, showNextBtn = true, showPrevBtn = true, errorTip = 'Was everything filled in correctly?') => {
    return {
        id: groupID,
        body: body,
        controls: {},
        showPrevBtn: showPrevBtn,
        showNextBtn: showNextBtn,
        errorTip: errorTip,
        errorHints: [],
        isValid: true,
    }
}

/**
 *
 * @param controlName
 * @param type
 * @param value
 * @param label
 * @param isRequired
 * @param placeholder
 * @param hint
 * @param errorTip
 * @param isVisible
 * @param hideFieldValidIcon
 * @param conjoinLabel
 * @returns {{isRequired: boolean, errorTip: string, defaultValue: string, isValid: boolean, label: string, isVisible: boolean, conjoinLabel: boolean, type: string, isInvalid: boolean, hideFieldValidIcon: boolean, isReadOnly: boolean, size: string, showError: boolean, hasChanged: boolean, hint: string, isPlaintext: boolean, name, placeholder: string, value: string}}
 */
export const props_FControl = (controlName, type = FORM_CONTROLS.text, value = '', label = '', isRequired = false, placeholder = '', hint = '', errorTip = 'Eh!?', isVisible = true, hideFieldValidIcon = false, conjoinLabel = false) => {
    return {
        name: controlName,
        type: type,
        label: label,
        hint: hint,
        errorTip: errorTip,
        showError: false,
        placeholder: placeholder,
        value: value,
        defaultValue: value,
        isRequired: isRequired,
        isVisible: isVisible,
        hasChanged: false, //set when defaultValue != value
        isValid: false,
        isInvalid: false,
        isPlaintext: false,
        isReadOnly: false,
        size: ' ',
        hideFieldValidIcon: hideFieldValidIcon,
        conjoinLabel: conjoinLabel
    }
}
