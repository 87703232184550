import {lf} from "../../DSYNR/Utils/debug";
import {APIS} from "../../DSYNR/Utils/wp.api";
import {networkRequest} from "../../DSYNR/Utils/network";
import {ACTIONS} from "./index";
import {APPDATA} from "../../__STORE";

export const set = (what, val) => {
    lf('set', what)
    return {
        type: ACTIONS.SET,
        payload: {
            what: what,
            val: val,
        }
    }
}

export const update = (what, val) => {
    lf('update', val)
    return {
        type: ACTIONS.update,
        payload: {
            what: what,
            val: val,
        }
    }
}

export const hideModal = (modalName) => {
    lf('hideModal', modalName)
    return {
        type: ACTIONS.hideModal,
        payload: {
            modalName: modalName
        }
    }
}

export const showModal = (modalName) => {
    lf('showModal', modalName)
    return {
        type: ACTIONS.showModal,
        payload: {
            modalName: modalName
        }
    }
}

export const showNotificationPanel = () => {
    lf('showNotificationPanel')
    return function (dispatch) {
        dispatch(update('notification', {isPanelVisible: true}))
        dispatch(blurMainScreen())
    }
}

export const addNew = (what) => {
    lf('addNew', what)
    return function (dispatch) {
        dispatch(update(what, {isNewVisible: true}))
    }
}

export const hideNotificationPanel = () => {
    lf('hideNotificationPanel')
    return function (dispatch) {
        dispatch(update('notification', {isPanelVisible: false}))
        dispatch(focusMainScreen())
    }
}

export const hideMenuPanel = () => {
    lf('hideMenuPanel')
    return function (dispatch) {
        dispatch(set('isMenuVisible', false))
        dispatch(focusMainScreen())
    }
}

export const showMenuPanel = () => {
    lf('showMenuPanel')
    return function (dispatch) {
        dispatch(set('isMenuVisible', true))
        dispatch(blurMainScreen())
    }
}

//@todo bug with count clearance?
export const clearAllNotifications = () => {
    lf('clearAllNotifications')
    return {
        type: ACTIONS.clearAllNotifications,
    }
}

export const changeScreen = (screenName) => {
    lf('changeScreen', screenName)
    return function (dispatch) {
        dispatch(exitCurrentScreen(screenName))
        dispatch(loadScreen(screenName))
    }
}

export const loadScreen = (screenName) => {
    lf('loadScreen', screenName)
    return {
        type: ACTIONS.loadScreen,
        payload: {
            screenName: screenName
        }
    }
}

export const exitCurrentScreen = () => {
    lf('exitCurrentScreen')
    return {
        type: ACTIONS.exitCurrentScreen,
    }
}

export const updateScreenBody = (screenName, body) => {
    lf('updateScreenBody')
    return {
        type: ACTIONS.updateScreenBody,
        payload: {
            screenName: screenName,
            body: body
        }
    }
}

// export const viewItems = (itemType) => {
//     lf('viewItems', itemType)
//     return {
//         type: ACTIONS.viewItems,
//         payload: {
//             itemType: itemType
//         }
//     }
// }

export const fetchAPI = (name, token, onSubmitSuccess = null, onSubmitError = null) => {
    return async function (dispatch) {
        //@todo dispatch('somthing like show loader...')
        let response = await networkRequest(APIS[name], false, token)

        lf('+++++++++++++++', response)

        if (response.type === 'cors') {
            //Fetch succeeded, validate response
            if (response.status === 200) {
                onSubmitSuccess(response)
            } else {
                //403 other?
                lf(403)
                onSubmitError()
            }
        } else {
            //Fetch failed, network issue
            // dispatch(networkFailure(formID, response))
            lf('network issue')
            onSubmitError()
        }
    }
}

export const apiFetched = (name, data) => {
    lf('apiFetched', name, data)
    return {
        type: ACTIONS.apiFetched,
        payload: {
            name: name,
            data: data
        }
    }
}

export const updateFutureAppointments = (data) => {
    lf('updateFutureAppointments', data)
    return {
        type: ACTIONS.updateFutureAppointments,
        payload: {
            data: data
        }
    }
}

export const recordsFetched = data => {
    lf('recordsFetched', data)
    const recordType = `${data.recordType}s`
    delete data.recordType
    lf(data, recordType, '...........data')
    return function (dispatch) {
        dispatch(set([recordType], data))
    }
}

export const fdFetched = data => {
    lf('fdFetched', data)
    return function (dispatch) {
        for (const kname in data) {
            if (kname === 'service' || kname === 'prototype') {
                dispatch(set(`${kname}s`, data[kname]))
            } else {
                dispatch(update(kname, {options: data[kname]}))
            }
        }
    }
}

export const iniFetched = data => {
    lf('iniFetched', data)
    return function (dispatch) {
        for (const kname in data) {
            if (kname === 'notifications') {
                dispatch(set('notifications', data[kname]))
            } else if (kname === 'futureAppointments') {
                dispatch(updateFutureAppointments(data[kname]))
            } else if (kname === 'overview') {
                dispatch(update('overview', {summary: data[kname]}))
            } else {
                dispatch(update(kname, data[kname]))
                if (kname === 'pref') {
                    dispatch(set('currentScreen', data[kname]['defaultScreen']))
                }
            }
        }
        // dispatch(changeScreen('dashboard'))
    }
}

export const updateAppStatus = msg => {
    lf('updateAppStatus', msg)
    return function (dispatch) {
        dispatch(update('app', {currentStatus: msg}))
    }
}

export const getServerUpdates = (token, updateFrequency, isUpdating) => {
    lf('getServerUpdates...')
    return function (dispatch) {
        dispatch(updateAppStatus('Checking for real-time updates...'))
        if (!isUpdating) {
            dispatch(update('updates', {isUpdating: true}))
            dispatch(fetchAPI('sup', token, data => {
                lf('.....updates fetched', data.data)
                const d = data.data
                for (const kname in d) {
                    if (kname === 'notifications') {
                        dispatch(set('notifications', d[kname]))
                    } else if (kname === 'futureAppointments') {
                        dispatch(updateFutureAppointments(d[kname]))
                    } else {
                        dispatch(update('overview', {summary: d[kname]}))
                        // if (kname === 'pref') {
                        //     dispatch(update('app', {currentScreen: data[kname]['defaultScreen']}))
                        // }
                    }
                }
                dispatch(update('updates', {isUpdating: false}))
                dispatch(updateAppStatus('App is up to date!'))
            }, err => {
                dispatch(updateAppStatus('Failed to update. Retrying in ' + (updateFrequency) / 60000 + ' Minutes'))
            }))
        }
    }
}

export const enableBackgroundServerUpdates = (updateFrequency, isUpdating, token) => {
    lf('enableBackgroundServerUpdates')
    return function (dispatch) {
        setInterval(() => {
            dispatch(getServerUpdates(token, updateFrequency, isUpdating))
        }, updateFrequency)
    }
}

export const updateNetworkStatus = (connection) => {
    lf('updateNetworkStatus', connection)
    return function (dispatch) {
        if (connection !== undefined && connection.rtt === 0) {
            dispatch(update('network', {isOnline: false}))
            dispatch(updateAppStatus('You are offline'))
        } else {
            dispatch(update('network', {isOnline: true}))
            dispatch(updateAppStatus('You are now online'))
        }
        update('network', {connection: connection})
    }
}

export const focusMainScreen = () => {
    return function (dispatch) {
        dispatch(set('isMainScreenFocused', true))
    }
}
export const blurMainScreen = () => {
    return function (dispatch) {
        dispatch(set('isMainScreenFocused', false))
    }
}
