import {lf} from "../DSYNR/Utils/debug";
import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import React from "react";
import {APPDATA} from "../__STORE";
import ASpinner from "../comps/ASpinner";
import {useDispatch, useSelector} from "react-redux";
import AddNewBtn from "../btns/AddNewBtn";
import {changeScreen, hideMenuPanel, hideNotificationPanel} from "../redux/actions/ACTIONS_APP";

export default function OverviewWidget() {
    lf('OverviewWidget')

    const dispatch = useDispatch()

    const icoOverview = useSelector(state => state.app.overview.ico)
    const overview = useSelector(state => state.app.overview.summary)

    const summary = Object.keys(overview).map((item, i) => {
        if (APPDATA[item] && item) {
            const oi = overview[item]
            const oiIco = APPDATA[item].ico
            const oiTitle = APPDATA[item].screenTitle

            return <ListGroup.Item className='p-0' key={i} action>
                <div className='hand p-3 d-inline-block w-75' onClick={() => {
                    dispatch(changeScreen(item))
                    // dispatch(hideMenuPanel())
                }}>
                    <span className="fs-4 opacity-25">{oiIco}</span>
                    <span className="lead">
                    <span className="ms-3 me-2 text-muted">{oi}</span>
                        {oiTitle}
                </span>
                </div>

                <div className='d-inline-block mt-2'>
                    {
                        APPDATA[item].new ? <AddNewBtn what={item} cls=''/> : ''
                    }
                </div>


            </ListGroup.Item>
        }
    })

    return <>
        <h3 className="fw-lighter display-6 opacity-75 text-secondary mb-4">
            <span className="text-secondary opacity-25">{icoOverview}</span> Manage
        </h3>
        <Card className="border-0 rounded shadow-sm">
            <Card.Body>
                <ListGroup variant={"flush"}>
                    {summary || <ASpinner/>}
                </ListGroup>
            </Card.Body>
        </Card>
    </>
}
