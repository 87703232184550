import {Spinner} from "react-bootstrap";
import {lf} from "../DSYNR/Utils/debug";

export default function ASpinner({name}) {
    lf('ASpinner', name)
    return <div className="text-center opacity-25">
        <Spinner size={"sm"} variant="secondary" animation="border" role="status"/>
        <pre className="m-0">Loading{name ? ' ' + name : ''}</pre>
    </div>
}
