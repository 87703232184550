import {lf} from "../DSYNR/Utils/debug";
import {APPDATA} from "../__STORE";
import AModal from "../comps/AModal";
import {useSelector} from "react-redux";

export default function AddNewScreens() {
    lf('AddNewScreens')

    //@todo use something like state.app.new (will require refactoring) instead of state.app to avoid re-rendering on every state change?
    const state = useSelector(state => state.app)

    const newScreens = Object.keys(APPDATA).map((screen, i) => {
        if (APPDATA[screen].new) {
            const screenTitle = APPDATA[screen].screenTitle
            const title = 'Add a New ' + screenTitle.substr(0, screenTitle.length - 1)
            return <AModal key={i}
                           name={screen} header={title}
                           isClosable={true} isVisible={state[screen].isNewVisible}
                           body={APPDATA[screen].new}
                           static={true}/>
        }
    })
    return <>{newScreens}</>

    // const isNewAppointmentVisible = useSelector(state => state.app.appointment.isNewVisible), isNewSessionVisible = useSelector(state => state.app.session.isNewVisible),
    //     isNewHistoryVisible = useSelector(state => state.app.history.isNewVisible), isNewAssessmentVisible = useSelector(state => state.app.assessment.isNewVisible),
    //     isNewReportVisible = useSelector(state => state.app.report.isNewVisible), isNewServiceVisible = useSelector(state => state.app.service.isNewVisible),
    //     isNewGCVisible = useSelector(state => state.app.gc.isNewVisible), isNewVoucherVisible = useSelector(state => state.app.voucher.isNewVisible),
    //     isNewOrderVisible = useSelector(state => state.app.order.isNewVisible), isNewInvoiceVisible = useSelector(state => state.app.invoice.isNewVisible),
    //     isNewUserVisible = useSelector(state => state.app.user.isNewVisible);

    // return <>
    //     <AModal isVisible={isNewAppointmentVisible} body={APPDATA.appointment.new}/>
    //     <AModal isVisible={isNewSessionVisible} body={APPDATA.session.new}/>
    //     <AModal isVisible={isNewHistoryVisible} body={APPDATA.history.new}/>
    //     <AModal isVisible={isNewAssessmentVisible} body={APPDATA.assessment.new}/>
    //     <AModal isVisible={isNewReportVisible} body={APPDATA.report.new}/>
    //     <AModal isVisible={isNewServiceVisible} body={APPDATA.service.new}/>
    //     <AModal isVisible={isNewGCVisible} body={APPDATA.gc.new}/>
    //     <AModal isVisible={isNewVoucherVisible} body={APPDATA.voucher.new}/>
    //     <AModal isVisible={isNewOrderVisible} body={APPDATA.order.new}/>
    //     <AModal isVisible={isNewInvoiceVisible} body={APPDATA.invoice.new}/>
    //     <AModal isVisible={isNewUserVisible} body={APPDATA.user.new}/>
    // </>
}
