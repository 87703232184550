import {
    BsFillPeopleFill,
    BsInfoCircleFill,
    FaCertificate, FaCog,
    FaDollarSign,
    FaFileInvoiceDollar, GiFluffyTrefoil,
    GiHealing,
    GiStethoscope,
    GrDocumentUser,
    GrYoga,
    IoDocument,
    RiHealthBookFill,
    RiMapPinUserFill, RiUserAddLine,
    RiUserReceivedFill, RiUserSmileLine,
    SiInfluxdb
} from "react-icons/all";
import NewAppointment from "./screens/appointment/NewAppointment";
import NewSession from "./screens/session/NewSession";
import NewService from "./screens/service/NewService";
import NewGiftCertificate from "./screens/gc/NewGiftCertificate";
import NewVoucher from "./screens/voucher/NewVoucher";
import NewOrder from "./screens/order/NewOrder";
import NewUser from "./screens/user/NewUser";
import {createBrowserHistory} from "history";
import configureStore from "./redux/store";
import Dashboard from "./screens/dashboard";
import Appointments from "./screens/appointment";
import AppScreen from "./screens/app/AppScreen";

export const APPDATA = {
    pref: {
        screenTitle: 'Preferences',
        screen: 'todo....',
        ico: <FaCog/>,
        isVisible: false,
        hasPage: true,
        updateFrequency: 1, //min
    },

    account: {
        screenTitle: 'Your Account',
        screen: 'todo....',
        ico: <GiFluffyTrefoil/>,
        isVisible: false,
        hasPage: true,
    },
    profile: {
        screenTitle: 'Your Profile',
        screen: 'todo....',
        ico: <RiUserSmileLine/>,
        isVisible: false,
        hasPage: true,
    },

    overview: {
        screenTitle: 'Overview',
        ico: <SiInfluxdb/>,
        summary: {},
    },

    dashboard: {
        screenTitle: 'Dashboard',
        screen: <Dashboard/>,
        ico: <SiInfluxdb/>,
        isVisible: false,
        hasPage: true,
    },

    appointments: [],
    appointment: {
        screenTitle: 'Appointments',
        screen: <Appointments/>,
        ico: <RiHealthBookFill/>,
        new: <NewAppointment/>,
        isVisible: false,
        isNewVisible: false,
        isDirectlyViewable: true,
        futureAppointments: {},
        options: {},
    },

    sessions: [],
    session: {
        screenTitle: 'Sessions',
        screen: 'todo....',
        ico: <GrYoga/>,
        isVisible: false,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },

    historys: [],
    history: {
        screenTitle: 'Histories',
        ico: <RiUserReceivedFill/>,
        isNewVisible: false,
        options: {},
    },

    assessments: [],
    assessment: {
        screenTitle: 'Assessments',
        ico: <GiStethoscope/>,
        isNewVisible: false,
        options: {},
    },

    reports: [],
    report: {
        screenTitle: 'Reports',
        ico: <GrDocumentUser/>,
        isNewVisible: false,
        options: {},
    },

    services: [],
    service: {
        screenTitle: 'Services',
        screen: 'todo....',
        ico: <GiHealing/>,
        new: <NewService/>,
        isVisible: false,
        isNewVisible: false,
        isDirectlyViewable: true,
    },
    serviceo: {
        screenTitle: 'Service Options',
        options: {},
    },

    gcs: [],
    gc: {
        screenTitle: 'Gift Certificates',
        screen: 'todo....',
        ico: <FaCertificate/>,
        new: <NewGiftCertificate/>,
        isVisible: false,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },

    vouchers: [],
    voucher: {
        screenTitle: 'Vouchers',
        screen: 'todo....',
        ico: <IoDocument/>,
        new: <NewVoucher/>,
        isVisible: false,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },

    orders: [],
    order: {
        screenTitle: 'Orders',
        screen: 'todo....',
        ico: <FaDollarSign/>,
        isVisible: false,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },

    invoices: [],
    invoice: {
        screenTitle: 'Invoices',
        ico: <FaFileInvoiceDollar/>,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },

    users: [],
    addresss: [],
    user: {
        screenTitle: 'People',
        screen: 'todo....',
        ico: <BsFillPeopleFill/>,
        new: <NewUser/>,
        isVisible: false,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },
    address: {
        screenTitle: 'Addresses',
        ico: <RiMapPinUserFill/>,
        options: {},
    },

    notifications: [],
    notification: {
        screenTitle: 'Notifications',
        screen: 'todo....',
        ico: <BsInfoCircleFill/>,
        isVisible: false,
        isPanelVisible: false,
        hasPage: true,
    },

    search: {},

    app: {
        currentStatus: '',
        dataLoaded: false,
    },
    network: {
        connection: null,
        isOnline: true,
    },
    prototypes: [],
    currentScreen: 'app',
    isMainScreenFocused: true,
    isMenuVisible: false,
    updates: {
        isUpdating: false,
    },
    dsynr: {
        logo: 'https://ajain.ca/Dsynr-logo.svg',
        url: 'https://dsynr.com',
    }
}

export const HISTORY = createBrowserHistory()
//@todo load state from localstorage
export const STORE = configureStore({})
