import AForm from "../DSYNR/Form";
import React from "react";
import {lf} from "../DSYNR/Utils/debug";
import {APPDATA, STORE} from "../__STORE";
import {storeLocally} from "../DSYNR/Utils/storage";
import {props_Form, props_FSection} from "../DSYNR/Form/_state";
import {getControl_Text, getControl_Password} from "../DSYNR/Form/_controls/_common";
import {set} from "../redux/actions/ACTIONS_APP";
import {useDispatch, useSelector} from "react-redux";
import {APIS} from "../DSYNR/Utils/wp.api";

export default function SignInForm() {
    lf('SignInForm')

    const dsynr = useSelector(state => state.app.dsynr)

    const dispatch = useDispatch()

    const formID = 'SignInForm'
    const formPostURL = APIS.TOKEN

    const formHeader = <img
        src={dsynr.logo}
        width="55"
        height="55"
        className="d-table mx-auto mb-4"
        alt='Dsynr'/>
    const formFooter = ''
    const formSubmitBtnTxt = 'Sign In'

    const formSections = {
        default: props_FSection('default',
            <>
                {getControl_Text(formID, 'default', 'username', '', '', true, 'Enter Your Email')}
                {getControl_Password(formID, 'default', 'password', '', true, 'Password')}
            </>
        )
    }

    const submissionSuccessful = () => {
        const curForm = STORE.getState().forms[formID]
        const response = curForm.response.data
        const responseData = response.data

        lf('submissionSuccessful for ' + formID, response, responseData)

        if (response.success) {
            let profile = APPDATA.profile
            for (const key in responseData) {
                profile[key] = responseData[key]
            }
            profile.isLogged = true

            lf('User ' + profile.id + ' has been authenticated...', profile)

            dispatch(set('profile', profile))
            //@todo use shorter keys for local storage to save space?

            let profileLocalStorage = {}
            profileLocalStorage.token = responseData.token
            profileLocalStorage.id = responseData.id
            storeLocally('p', profileLocalStorage, true)

        } else {
            //@todo responseData.success !=true is not yet tested
            alert('responseData.success failure')
        }
    }

    const submissionError = (formID, error) => {
        lf('submissionError', STORE.getState().forms)
        // dispatch(submissionFailed(formID, error))
    }

    return <AForm
        postURL={formPostURL}
        onSubmitSuccess={submissionSuccessful}
        onSubmitError={submissionError}
        {...props_Form(formID, formSections, false, formHeader, formFooter, formSubmitBtnTxt,'',true,false,false)}
    />
}
