//@todo sync with google cal, set times
import {lf} from "../../Utils/debug";
import {useDispatch} from "react-redux";
import {updateControlVal} from "../_actions";
import DatePicker from "react-datepicker";
import {setHours, setMinutes} from "date-fns";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";

export default function Time(controlProps) {
    lf('Time', controlProps)
    const dispatch = useDispatch()

    const changeTime = (time) => {
        controlProps.focusControl()
        dispatch(updateControlVal(controlProps.name, time, controlProps.secID, controlProps.formID))
    }

    const initialTime = new Date(controlProps.value)

    return <DatePicker
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Time"
        timeFormat="hh:mm a"
        selected={initialTime}
        minTime={setHours(setMinutes(new Date(), 0), 11)}
        maxTime={setHours(setMinutes(new Date(), 30), 17)}
        onClickOutside={controlProps.blurControl}
        onChange={changeTime}
        inline
    />
}
