import {lf} from "../../DSYNR/Utils/debug";
import {Col, Row} from "react-bootstrap";
import React from "react";
import OverviewWidget from "../../widgets/OverviewWidget";
import FutureAppointmentsWidget from "../../widgets/FutureAppointmentsWidget";
import ProfileWidget from "../../widgets/ProfileWidget";
import AccountWidget from "../../widgets/AccountWidget";
import GoToWidget from "../../widgets/GoToWidget";

export default function DashboardWidgets() {
    lf('DashboardWidgets')

    return <>
        <Row className="pb-5">
            <Col xs={12}>
                <FutureAppointmentsWidget/>
            </Col>
        </Row>
        <Row className="pt-5">
            <Col lg={4}>
                <OverviewWidget/>
            </Col>
            <Col lg={4}>
                <ProfileWidget/>
                <hr className='my-4 bg-white'/>
                <AccountWidget/>
            </Col>
            <Col lg={4}>
                <GoToWidget/>
            </Col>
        </Row>
    </>
}
