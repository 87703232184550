import {useDispatch, useSelector} from "react-redux";
import {updateNetworkStatus} from "../../redux/actions/ACTIONS_APP";
import {FaWifi, RiSignalWifiOffFill} from "react-icons/all";
import React from "react";

export default function NetworkBadge() {

    const dispatch = useDispatch()

    const isOnline = useSelector(state => state.app.network.isOnline)

    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
    connection.addEventListener('change', () => {
        dispatch(updateNetworkStatus(connection))
    });

    return <>
        <div className='float-end position-relative p-4 fs-4 hand'
             onClick={() => {
             }}>
            {
                isOnline ? <FaWifi className="text-info"/>
                    : <RiSignalWifiOffFill className="text-danger"/>
            }
        </div>
    </>
}
