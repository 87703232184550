import {Button, ButtonGroup} from "react-bootstrap";
import React from "react";
import {lf} from "../Utils/debug";

export default function FormWizardController(props) {

    lf('FormWizardController', props)

    const wizard = props.wizard
    const showPrevBtn = wizard.showPrevBtn
    const showNextBtn = wizard.showNextBtn

    return <div className="mx-auto d-table">
        <ButtonGroup size="lg">

            <Button
                className={showPrevBtn ? "" : "d-none"} variant="outline-primary"
                onClick={props.gotoPreviousWizardStep}
            >
                Previous
            </Button>

            <Button
                className={showNextBtn ? "" : "d-none"}
                onClick={props.gotoNextWizardStep}
            >
                Next
            </Button>

        </ButtonGroup>
    </div>
}
