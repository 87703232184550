import {l, lf} from "../../DSYNR/Utils/debug";
import {updateObj} from "../../DSYNR/Utils/obj";
import {APPDATA} from "../../__STORE";
import {ListGroup} from "react-bootstrap";
import {ACTIONS} from "../actions";
import {set} from "../actions/ACTIONS_APP";

const switchNotDefined = (caseName, action) => {
    l(`SWITCH CASE NOT DEFINED FOR :: ${caseName}`, action)
}

export default function REDUCER_APP(state = APPDATA, action) {
    lf('REDUCER_APP', state, action)

    let app = state.app
    let modals = state.modals
    let screens = state.screens
    let notificationPanel = state.notificationPanel

    switch (action.type) {
        //works only for first level values
        case ACTIONS.SET:
            return updateObj(state, {[action.payload.what]: action.payload.val})

        case ACTIONS.update:
            let pw = action.payload.what
            let pv = action.payload.val
            let w = state[pw]
            for (const k in pv) {
                w = updateObj(w, {...w, [k]: pv[k]})
            }
            return updateObj(state, {...state, [pw]: w})

        case ACTIONS.loadScreen:
            return updateObj(state, {...state, 'currentScreen': action.payload.screenName})

        case ACTIONS.exitCurrentScreen:
            return updateObj(state, {...state, 'currentScreen': 'app'})

        case ACTIONS.updateFutureAppointments:
            const futureAppointments = action.payload.data
            let appointment = state.appointment
            appointment = updateObj(appointment, {...appointment, futureAppointments: futureAppointments})
            return updateObj(state, {...state, appointment: appointment})

        case ACTIONS.updateScreenBody:
            const screenName = action.payload.screenName
            let screen = screens[screenName]
            screen = updateObj(screen, {...screen, body: action.payload.body})
            screens = updateObj(screens, {...screens, [screenName]: screen})
            return updateObj(state, {...state, screens: screens})

        case ACTIONS.showModal:
            modals = updateObj(modals, {...modals, [action.payload.modalName]: true})
            return updateObj(state, {...state, modals: modals})

        case ACTIONS.hideModal:
            modals = state.modals
            modals = updateObj(modals, {...modals, [action.payload.modalName]: false})
            return updateObj(state, {...state, modals: modals})

        //@todo bug with count clearance?
        case ACTIONS.clearAllNotifications:
            return updateObj(state, {...state, notifications: []})


        case ACTIONS.apiFetched:
            const name = action.payload.name
            alert('apiFetched.....@todo?')
            return state

        default:
            switchNotDefined(action.type, action)
            return state
    }
}

const setDashboardWidgetData = data => {
    lf('setDashboardWidgetData.....', data)

    //@todo
    // const accountData = data.account

    // const overviewData = data.overview
    // const overview = <ListGroup variant={"flush"}>
    //     {
    //         Object.keys(overviewData).map((key, index) => {
    //             return <ListGroup.Item key={index} action>
    //                 {overviewData[key]} {key}
    //             </ListGroup.Item>
    //         })
    //     }
    // </ListGroup>

    const appointmentsData = data.appointment.data
    lf('appointmentsData.............', appointmentsData)
    lf('+++++++++++++++++', appointmentsData)
    const appointments = <ListGroup variant={"flush"}>
        {
            Object.keys(appointmentsData).map((key, index) => {
                return <ListGroup.Item key={index} action>
                    {appointmentsData[key]}
                </ListGroup.Item>
            })
        }
    </ListGroup>

    let dashboardWidgets = {}
    // dashboardWidgets.summary.body = overview
    // dashboardWidgets.accountOverview = accountData
    dashboardWidgets.appointments = appointments
    return dashboardWidgets
}
