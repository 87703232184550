export const baseAPIURL = () => {
    return URLS.SITE + CONF.APIS.BASE_SITE;
}
const API_WP = () => {
    return baseAPIURL() + CONF.APIS.BASE_WP;
}
const API_WP_DSYNR = () => {
    return baseAPIURL() + CONF.APIS.BASE_DSYNR;
}

const CONF = {
    "PROTOCOL": "https://",
    "DOMAIN": window.location.hostname === 'app.aarti.ca' ? 'cms.aarti.ca' : 'aarti', //@todo refine?
    "APIS": {
        "BASE_SITE": "api/",
        "BASE_WP": "wp/v2/",
        "BASE_DSYNR": "dsynr/",
    },
}

export const URLS = {
    "SITE": CONF.PROTOCOL + CONF.DOMAIN + "/",
}

export const APIS = {
    // "posts": API_WP_DSYNR() + "posts",
    // "pages": API_WP_DSYNR() + "pages",

    "TOKEN": baseAPIURL() + "jwt-auth/v1/token",

    "profile": API_WP_DSYNR() + "profile",
    "pref": API_WP_DSYNR() + "pref",

    "sup": API_WP_DSYNR() + "sup",
    "ini": API_WP_DSYNR() + "ini",
    "fd": API_WP_DSYNR() + "fd",

    "search": API_WP_DSYNR() + "search",

    "add": API_WP_DSYNR() + "add",

    "users": API_WP_DSYNR() + "users",
    "services": API_WP_DSYNR() + "services",
    "orders": API_WP_DSYNR() + "orders",
    "invoices": API_WP_DSYNR() + "invoices",
    "appointments": API_WP_DSYNR() + "appointments",
    "historys": API_WP_DSYNR() + "histories",
    "assessments": API_WP_DSYNR() + "assessments",
    "reports": API_WP_DSYNR() + "reports",
    "sessions": API_WP_DSYNR() + "reports",
    "addresses": API_WP_DSYNR() + "addresses",
    "vouchers": API_WP_DSYNR() + "vouchers",
    "gcs": API_WP_DSYNR() + "gc",
}
