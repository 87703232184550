import {lf} from "../DSYNR/Utils/debug";
import React from "react";
import {Button, Card, ListGroup} from "react-bootstrap";
import {BsThreeDots} from "react-icons/all";
import {useSelector} from "react-redux";
import ASpinner from "../comps/ASpinner";

export default function ProfileWidget() {
    lf('ProfileWidget')

    const icoProfile = useSelector(state => state.app.profile.ico)
    const profile = useSelector(state => state.app.profile)

    const summary = <>
        <Card.Title>{profile.title}. {profile.fname} {profile.lname}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{profile.role}</Card.Subtitle>
        <Card.Text>
            Registered since {profile.registered}
        </Card.Text>
        <Button variant={"light"} className="fs-4"><BsThreeDots/></Button>
    </>

    return <>
        <h3 className="fw-lighter display-6 opacity-75 text-secondary mb-4">
            <span className="text-secondary opacity-25">{icoProfile}</span> Your Profile
        </h3>
        <Card className="border-0 rounded shadow-sm">
            <Card.Body>
                {summary || <ASpinner/>}
            </Card.Body>
        </Card>
    </>
}
