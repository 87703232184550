import {lf} from "../../Utils/debug";
import {useDispatch} from "react-redux";
import {Form} from "react-bootstrap";
import {updateControlVal} from "../_actions";
import React from "react";

export default function Textarea(controlProps) {
    lf('Textarea', controlProps)
    const dispatch = useDispatch()
    return <Form.Control as={controlProps.type}
                         isValid={controlProps.isValid}
                         isInvalid={controlProps.isInvalid}
                         value={controlProps.value}
                         placeholder={controlProps.placeholder}
                         plaintext={controlProps.isPlaintext}
                         readOnly={controlProps.isReadOnly}
                         required={controlProps.isRequired}
                         rows={3}
                         onFocus={controlProps.focusControl}
                         onBlur={controlProps.blurControl}
                         onChange={(event) => {
                             dispatch(updateControlVal(controlProps.name, event.target.value, controlProps.secID, controlProps.formID))
                         }}
    />
}
