import {getTimeOfDay} from "../DSYNR/Utils/time";
import {lf} from "../DSYNR/Utils/debug";
import {getRandomArrayItem} from "../DSYNR/Utils/calculus";

export const Greeting = ({name}) => {
    lf(`DGreeting :: ${name}`);

    const punctuation = [',', '!',];
    const greetings = {
        'morning': [
            'Good morning',
        ],
        'afternoon': [
            'Good afternoon',
        ],
        'evening': [
            'Good evening',
        ],
        'general': [
            'Hello',
            'Hey',
            'Hi',
            'Greetings',
        ],
    };

    let theGreeting = ` ${name}${getRandomArrayItem(punctuation)}`;

    let tod = getTimeOfDay();

    if (tod in greetings) {
        theGreeting = greetings[tod] + theGreeting;
    } else {
        theGreeting = getRandomArrayItem(greetings.general) + theGreeting;
    }

    return theGreeting;
}
