import {lf} from "../../Utils/debug";
import {useDispatch} from "react-redux";
import {Form} from "react-bootstrap";
import {updateControlVal} from "../_actions";
import React from "react";

export default function Select(controlProps) {
    lf('Select', controlProps)
    const dispatch = useDispatch()

    let options = []
    let defaultVal = 0
    for (const [key, val] of Object.entries(controlProps.options)) {
        if (defaultVal === 0 && controlProps.value === '') {
            defaultVal++
            defaultVal = key
        }
        lf('defaultVal=======',defaultVal)
            options.push(<option value={key}>{val}</option>)
    }
    return <Form.Control as={controlProps.type}
                         isValid={controlProps.isValid}
                         isInvalid={controlProps.isInvalid}
                         value={controlProps.value || defaultVal}
                         required={controlProps.isRequired}
                         size={controlProps.size}
                         custom
                         onFocus={controlProps.focusControl}
                         onBlur={controlProps.blurControl}
                         onChange={(event) => {
                             dispatch(updateControlVal(controlProps.name, event.target.value, controlProps.secID, controlProps.formID))
                         }}
    >
        {React.Children.toArray(options)}
    </Form.Control>
}
