import {lf} from "../Utils/debug";
import {FORM_CONTROLS} from "./_state";
import {networkRequest} from "../Utils/network";

/**
 * Use instead of manually typing every action type value to avoid typos and maintain consistency
 * @param name
 * @returns {string}
 */
const getFullActionName = (name) => {
    return 'FORM / ' + name
}

const ACTIONS = {
    registerForm: getFullActionName('registerForm'),

    focusForm: getFullActionName('focusForm'),
    blurForm: getFullActionName('blurForm'),

    validateForm: getFullActionName('validateForm'),

    validationSuccessful: getFullActionName('validationSuccessful'),
    validationFailed: getFullActionName('validationFailed'),
    validateField: getFullActionName('validateField'),

    submitForm: getFullActionName('submitForm'),
    formSubmitted: getFullActionName('formSubmitted'),
    submissionSuccessful: getFullActionName('submissionSuccessful'),
    submissionFailed: getFullActionName('submissionFailed'),
    networkFailure: getFullActionName('networkFailure'),

    gotoNextWizardStep: getFullActionName('gotoNextWizardStep'),
    gotoPreviousWizardStep: getFullActionName('gotoPreviousWizardStep'),
    updateWizardControls: getFullActionName('updateWizardControls'),

    focusControl: getFullActionName('focusControl'),
    blurControl: getFullActionName('blurControl'),
    // registerControl: getFullActionName('registerControl'),
    updateControlVal: getFullActionName('updateControlVal'),
    validateControl: getFullActionName('validateControl'),
    neutraliseControl: getFullActionName('neutraliseControl'),
    showControl: getFullActionName('showControl'),
    hideControl: getFullActionName('hideControl'),

    focusSection: getFullActionName('focusSection'),
    validateSection: getFullActionName('validateSection'),
}
export default ACTIONS

export const focusForm = (formID) => {
    return {
        type: ACTIONS.focusForm,
        payload: {
            id: formID,
        }
    }
}

export const blurForm = (formID) => {
    return {
        type: ACTIONS.blurForm,
        payload: {
            id: formID,
        }
    }
}

export const validateForm = (formID) => {
    return {
        type: ACTIONS.validateForm,
        payload: {
            id: formID,
        }
    }
}

export const registerForm = (props) => {
    return {
        type: ACTIONS.registerForm,
        payload: {
            props: props,
        }
    }
}

const networkFailure = (formID, response) => {
    lf('networkFailure', formID, response)
    return {
        type: ACTIONS.networkFailure,
        payload: {
            id: formID,
            response: response,
        }
    }
}

const submissionSuccessful = (formID, response, onSubmitSuccess) => {
    lf('submissionSuccessful', formID, response)
    return {
        type: ACTIONS.submissionSuccessful,
        payload: {
            id: formID,
            response: response,
            onSubmitSuccess: onSubmitSuccess,
        }
    }
}

const submissionFailed = (formID, response, onSubmitError) => {
    lf('submissionFailed', formID, response)
    return {
        type: ACTIONS.submissionFailed,
        payload: {
            id: formID,
            response: response,
            onSubmitError: onSubmitError,
        }
    }
}

const formSubmitted = (formID, response, onSubmitSuccess = false, onSubmitError = false) => {
    lf('formSubmitted', formID, response)
    return function (dispatch) {
        if (response.type === 'cors') {
            //Fetch succeeded, validate response
            if (response.status === 200) {
                dispatch(submissionSuccessful(formID, response, onSubmitSuccess))
            } else {
                //403
                dispatch(submissionFailed(formID, response, onSubmitError))
            }
        } else {
            //Fetch failed, network issue
            dispatch(networkFailure(formID, response))
        }
    }
}

export const submitForm = (formState, token = false) => {
    lf('submitForm', formState, token)
    return async function (dispatch) {
        //build formData
        let sections = formState.sections
        let formData = {}
        for (const sectionID in sections) {
            const sectionControls = sections[sectionID].controls
            for (const sectionControlID in sectionControls) {
                const control = sectionControls[sectionControlID]
                if (control.hasChanged || formState.sendAllData) {
                    formData[sectionControlID] = (control.type === FORM_CONTROLS.time) ? convert2LocalTime(control.value) : control.value
                }
            }
        }
        //submit only if there's any data
        if (Object.keys(formData).length > 0) {
            lf('Submitting FormData.....', formData)
            let response = await networkRequest(formState.postURL, formData, token, 'POST')
            dispatch(formSubmitted(formState.id, response, formState.onSubmitSuccess, formState.onSubmitError))
        }
    }
}

export const convert2LocalTime = time => {
    return time.toLocaleString()
}

export const gotoNextWizardStep = (formID) => {
    return {
        type: ACTIONS.gotoNextWizardStep,
        payload: {
            id: formID,
        }
    }
}

export const gotoPreviousWizardStep = (formID) => {
    return {
        type: ACTIONS.gotoPreviousWizardStep,
        payload: {
            id: formID,
        }
    }
}

export const updateWizardControls = (formID, props) => {
    return {
        type: ACTIONS.updateWizardControls,
        payload: {
            id: formID,
            props: props,
        }
    }
}
export const focusControl = (controlName, secID, formID) => {
    return {
        type: ACTIONS.focusControl,
        payload: {
            name: controlName,
            secID: secID,
            id: formID,
        }
    }
}

export const blurControl = (controlName, secID, formID) => {
    return {
        type: ACTIONS.blurControl,
        payload: {
            name: controlName,
            secID: secID,
            id: formID,
        }
    }
}

export const showInput = (controlName) => {
    return {
        type: ACTIONS.showControl,
        payload: {controlName: controlName}
    }
}

export const hideInput = (controlName) => {
    return {
        type: ACTIONS.hideControl,
        payload: {controlName: controlName}
    }
}

export const updateControlVal = (controlName, controlVal, secID, formID) => {
    return {
        type: ACTIONS.updateControlVal,
        payload: {
            name: controlName,
            value: controlVal,
            secID: secID,
            id: formID,
        }
    }
}

export const validateControl = (controlName, secID, formID) => {
    return {
        type: ACTIONS.validateControl,
        payload: {
            name: controlName,
            secID: secID,
            id: formID,
        }
    }
}

export const neutraliseControl = (controlName, secID, formID) => {
    return {
        type: ACTIONS.neutraliseControl,
        payload: {
            name: controlName,
            secID: secID,
            id: formID,
        }
    }
}


export const focusSection = (secID) => {
    return {
        type: ACTIONS.focusSection,
        payload: {
            secID: secID
        }
    }
}

export const validateSection = (secID = null, formID = null) => {
    return {
        type: ACTIONS.validateSection,
        payload: {
            secID: secID,
            id: formID,
        }
    }
}
