import {lf} from "../DSYNR/Utils/debug";
import React from "react";
import {Button, Card} from "react-bootstrap";
import {BsThreeDots, GoPlus} from "react-icons/all";
import {useDispatch, useSelector} from "react-redux";
import ASpinner from "../comps/ASpinner";
import {addNew} from "../redux/actions/ACTIONS_APP";
import AddNewBtn from "../btns/AddNewBtn";

export default function FutureAppointmentsWidget() {

    const dispatch = useDispatch()

    lf('FutureAppointmentsWidget')

    const icoAppointment = useSelector(state => state.app.appointment.ico)
    const futureAppointments = useSelector(state => state.app.appointment.futureAppointments)
    const totalFutureAppointments = Object.keys(futureAppointments).length
    let upcomingAppointments
    if (totalFutureAppointments > 0) {
        upcomingAppointments = Object.keys(futureAppointments).map((upcomingAppointment, i) => {
            const ua = futureAppointments[upcomingAppointment]
            return <Card key={i} className="border-0 rounded shadow-sm me-4 mt-4 float-start">
                <Card.Body>
                    <Card.Title>{ua.service}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{ua.dateTime}</Card.Subtitle>
                    <Card.Text>
                        {ua.duration} Session for {ua.user}
                    </Card.Text>
                    <Button variant={"light"} className="fs-4"><BsThreeDots/></Button>
                </Card.Body>
            </Card>
        })
    } else {
        upcomingAppointments = <p className="lead text-secondary">You don't have any upcoming appointments at the moment.
            <br/><span className="btn-link hand" onClick={() => {
                dispatch(addNew('appointment'))
            }}>Add an Appointment?</span></p>
    }

    return <>
        <h2 className="fw-lighter display-5 text-secondary mb-4">
            <span className="text-secondary opacity-25">{icoAppointment}</span> {totalFutureAppointments > 0 ? totalFutureAppointments + ' ' : ' No '}
            Upcoming Appointment{(totalFutureAppointments > 1 || totalFutureAppointments === 0) ? 's' : ''}
            <AddNewBtn what='appointment' cls='ms-3'/>
        </h2>
        {upcomingAppointments || <ASpinner/>}
    </>
}
