import {lf} from "../DSYNR/Utils/debug";
import {APPDATA} from "../__STORE";
import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import ASpinner from "../comps/ASpinner";
import React from "react";
import {CgArrowLongRightC} from "react-icons/all";
import {changeScreen} from "../redux/actions/ACTIONS_APP";
import {useDispatch} from "react-redux";

export default function GoToWidget() {
    lf('GoToWidget')

    const dispatch = useDispatch()

    const gotoItems = Object.keys(APPDATA).map((screen, i) => {
        if (APPDATA[screen].hasPage) {
            const screenTitle = APPDATA[screen].screenTitle
            const ico = APPDATA[screen].ico
            return <ListGroupItem key={i} action>
                <div className='p-3' onClick={() => {
                    dispatch(changeScreen(screen))
                }}>
                    <span className="fs-4 opacity-25">{ico}</span>
                    <span className="lead">
                    <span className="ms-3 me-2 text-muted">{screenTitle}</span>
                </span>
                </div>
            </ListGroupItem>
        }
    })

    return <>
        <h3 className="fw-lighter display-6 opacity-75 text-secondary mb-4">
            Go To <span className="text-secondary opacity-50"><CgArrowLongRightC/></span>
        </h3>
        <Card className="border-0 rounded shadow-sm">
            <Card.Body>
                <ListGroup variant={"flush"}>
                    {gotoItems || <ASpinner/>}
                </ListGroup>
            </Card.Body>
        </Card>
    </>
}
