import {Button, ButtonGroup, Col, Container, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {IoCheckmarkDoneSharp, IoNotifications} from "react-icons/all";
import {changeScreen, clearAllNotifications, hideNotificationPanel} from "../../redux/actions/ACTIONS_APP";
import {useDispatch, useSelector} from "react-redux";

export default function NotificationSlot() {

    const dispatch = useDispatch()

    const notifications = useSelector(state => state.app.notifications)
    const notificationPanel = useSelector(state => state.app.notification)
    const notificationKeys = Object.keys(notifications)
    const notificationsContent = notificationKeys.map(notification => {
        const n = notifications[notification]
        return <ListGroupItem key={notification} action className="py-3">
            <div className="d-flex w-100 align-items-center justify-content-between">
                <div>
                    <span className="fs-4 me-3">{notificationPanel.ico}</span>
                    <span className="lead">{n.title}</span>
                </div>
                <small>Day</small>
            </div>
            <div className="col-10 my-1">{n.content}</div>
        </ListGroupItem>
    })

    return <Container className='bg-white bg-gradient p-5'>

        <Row>
            <Col>

                <div className='float-start p-3 bg-white shadow-sm rounded-circle me-3'>
                    <IoNotifications className="text-secondary mt-1 fs-2"/>
                </div>

                <div className='float-start w-75'>

                    {notificationKeys.length === 0 ? <p className='lead mt-4'>You don't have any new notifications.</p>
                        : <>
                            <ButtonGroup className='mx-auto d-table'>
                                <Button variant="outline-dark"
                                        onClick={() => {
                                            dispatch(clearAllNotifications())
                                        }}><IoCheckmarkDoneSharp/> Clear All</Button>
                                <Button variant={"secondary"}
                                        onClick={() => {
                                            dispatch(changeScreen('notification'))
                                            dispatch(hideNotificationPanel())
                                        }}
                                >View All Notifications</Button>
                            </ButtonGroup>

                            <ListGroup className="list-group-flush mt-3">
                                {notificationsContent}
                            </ListGroup>
                        </>
                    }
                </div>

            </Col>
        </Row>

    </Container>
}
