import bg from '../../img/bg-dashboard.jpg'
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import React from "react";
import {lf} from "../../DSYNR/Utils/debug";
import {useDispatch, useSelector} from "react-redux";
import AppScreen from "./AppScreen";
import NotificationPanel from "../notification/NotificationPanel";
import AddNewScreens from "../AddNewScreens";
import MenuPanel from "../menu/MenuPanel";

export default function AppMain() {
    lf('AppMain')

    let currentScreen = useSelector(state => state.app.currentScreen)
    let screen = useSelector(state => state.app[currentScreen].screen) || <AppScreen/>
    const appDataLoaded = useSelector(state => state.app.app.dataLoaded)
    const isMainScreenFocused = useSelector(state => state.app.isMainScreenFocused)

    let mainCls = 'mt-5 pt-5 ' + (isMainScreenFocused ? '' : 'opacity-25')
    return <>
        {appDataLoaded ? <AppHeader/> : ''}
        <main className={mainCls} style={{backgroundImage: `url(${bg})`}}>
            {screen}
        </main>
        {appDataLoaded ? <>
            <MenuPanel/>
            <NotificationPanel/>
            <AddNewScreens/>
            {/*<AppFooter/>*/}
        </> : ''}
    </>
}
