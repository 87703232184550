import {lf} from "../../DSYNR/Utils/debug";
import {Col, Container, Image, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import AProgressBar from "../../comps/AProgressBar";
import React from "react";
import {hideMenuPanel, hideNotificationPanel, set, showMenuPanel, showNotificationPanel, updateAppStatus} from "../../redux/actions/ACTIONS_APP";
import NetworkBadge from "./NetworkBadge";

export default function AppHeader() {
    lf('AppHeader');

    const dispatch = useDispatch()

    const isMenuVisible = useSelector(state => state.app.isMenuVisible)
    const isPanelVisible = useSelector(state => state.app.notification).isPanelVisible
    const notificationsCount = Object.keys(useSelector(state => state.app.notifications)).length
    const hasNotifications = notificationsCount > 0

    const dsynr = useSelector(state => state.app.dsynr)
    const account = useSelector(state => state.app.account)
    const profile = useSelector(state => state.app.profile)

    const currentStatus = useSelector(state => state.app.app.currentStatus)

    let currentScreen = useSelector(state => state.app.currentScreen)
    let screenIco = useSelector(state => state.app[currentScreen].ico) || false
    let screenTitle = useSelector(state => state.app[currentScreen].screenTitle) || false

    return <Container as={"header"} fluid className="bg-light text-white px-4 position-fixed top z3">
        <Row>
            <Col xs={7} md={4}>

                <Image src={account.logo || dsynr.logo}
                       roundedCircle width="50" height="50" className="float-start hand mt-3 bg-info bg-gradient"
                       onClick={() => {
                           isMenuVisible ? dispatch(hideMenuPanel()) : dispatch(showMenuPanel())
                       }}/>
            </Col>

            <Col className="d-none d-md-block text-center">
                <h1 className="fs-3 fw-light text-secondary mt-3 mb-0"><span className="opacity-25">{screenIco}</span> {screenTitle}</h1>
                <p className="mb-0 fs-6 text-muted font-monospace opacity-50">{currentStatus}</p>
            </Col>

            <Col xs={5} md={4}>
                <div className="float-end fs-4 p-3 ms-3 hand"
                     onClick={() => {
                         isPanelVisible ? dispatch(hideNotificationPanel()) : dispatch(showNotificationPanel())
                     }}>
                    <Image src={profile.img || dsynr.logo}
                           roundedCircle width="50" height="50"/>
                    {!hasNotifications ? '' : <div className="notificationBadge position-absolute fs-6 fw-bold bg-danger rounded-circle text-center border border-1 border-white mt-1">
                        {notificationsCount}
                    </div>}
                </div>

                <NetworkBadge/>
            </Col>

            <AProgressBar/>
        </Row>
    </Container>
}
