import ACTIONS from "./_actions";
import {getObjKeys, updateObj} from "../Utils/obj";
import {lf} from "../Utils/debug";
import INITIAL_STATE_FORMS, {FORM_CONTROLS} from "./_state";
import {isValidEmail} from "../Utils/misc";

let formState,
    id,
    props,
    controlsGroups,
    currentForm,
    wizard,
    activeIndex,
    maxIndex,
    control,
    controlName,
    activeSection,
    sections,
    sectionID,
    sectionIDs = [],
    section,
    controls, isValid, isInvalid,
    newValue, updatedValue,
    response

export default function REDUCER_Forms(state = INITIAL_STATE_FORMS, action) {

    const PAYLOAD = action.payload

    if (PAYLOAD) {
        id = PAYLOAD.id
        props = PAYLOAD.props
        formState = state[id]
    }

    switch (action.type) {

        case ACTIONS.registerForm:
            sections = props.sections
            sectionIDs = getObjKeys(sections)

            maxIndex = sectionIDs.length
            props = updateObj(props, {wizard: {...props.wizard, maxIndex: maxIndex}})

            sectionIDs.map(sectionID => {
                lf('registerForm/sectionControls RUNNING NOW....', sectionID, sections[sectionID])
                section = sections[sectionID]
                let secChildren = section.body ? section.body.props.children : null
                let sectionControls = secChildren ? getSectionControls(secChildren) : {}
                lf('registerForm/sectionControls ::::', sectionControls)
                let secControls = props.sections[sectionID].controls
                secControls = updateObj(secControls, {...secControls, ...sectionControls})
                section = updateObj(section, {controls: secControls})

                sections = updateObj(sections, {[sectionID]: section})

                props = updateObj(props, {sections: {...props.sections, ...sections}})
            })

            return updateObj(state, {...state, [props.id]: props})

        case ACTIONS.focusForm:
            currentForm = state.currentForm
            currentForm = updateObj(currentForm, {id: id})
            return updateObj(state, {...state, currentForm: currentForm})

        case ACTIONS.blurForm:
            currentForm = state.currentForm
            currentForm = updateObj(currentForm, {id: null})
            return updateObj(state, {...state, currentForm: currentForm})

        case ACTIONS.validateForm:
            lf('@todo validateForm')
            return state

        case ACTIONS.validationSuccessful:
            lf('@todo validationSuccessful')
            return state

        case ACTIONS.validationFailed:
            lf('@todo validationFailed')
            return state

        case ACTIONS.networkFailure:
            alert(PAYLOAD.response.message + ' ' + formState.postURL)
            return state

        case ACTIONS.submissionSuccessful:
            response = PAYLOAD.response
            const onSubmitSuccess = PAYLOAD.onSubmitSuccess
            currentForm = formState
            currentForm = updateObj(currentForm, {
                response: response,
                isValid: response.ok,
                errorTip: response.url + ' ' + response.statusText + ` (${response.status})`,
            })
            setTimeout(onSubmitSuccess)
            return updateObj(state, {...state, [id]: currentForm})

        case ACTIONS.submissionFailed:
            response = PAYLOAD.response
            const onSubmitError = PAYLOAD.onSubmitError
            currentForm = formState
            currentForm = updateObj(currentForm, {
                response: response,
                isValid: response.ok,
                errorTip: response.url + ' ' + response.statusText + ` (${response.status})`,
            })
            setTimeout(onSubmitError)
            return updateObj(state, {...state, [id]: currentForm})

        case ACTIONS.gotoNextWizardStep:
            wizard = formState.wizard
            activeIndex = wizard.activeIndex
            let curSec = getObjKeys(formState.sections)[activeIndex]
            curSec = formState.sections[curSec]
            lf('curSec.................', curSec)
            if (curSec.isValid) {
                maxIndex = wizard.maxIndex
                if (activeIndex < maxIndex) {
                    wizard = updateObj(wizard, {activeIndex: activeIndex + 1})
                    formState = updateObj(formState, {...formState, wizard: wizard})

                    return updateObj(state, {...state, [id]: formState})
                }
            }
            return state

        case ACTIONS.gotoPreviousWizardStep:
            wizard = formState.wizard
            activeIndex = wizard.activeIndex
            maxIndex = wizard.maxIndex
            if (activeIndex > 0) {
                wizard = updateObj(wizard, {activeIndex: activeIndex - 1})
                formState = updateObj(formState, {...formState, wizard: wizard})

                return updateObj(state, {...state, [id]: formState})
            }
            break

        case ACTIONS.updateWizardControls:
            sectionIDs = getObjKeys(props.sections)
            wizard = props.wizard
            activeIndex = wizard.activeIndex
            maxIndex = wizard.maxIndex
            activeSection = sectionIDs[wizard.activeIndex]
            activeSection = props.sections[activeSection]

            let showPrevBtn = (activeIndex > 0) && activeSection.showPrevBtn
            let showNextBtn = (activeIndex < maxIndex - 1) && activeSection.showNextBtn

            wizard = updateObj(wizard, {showPrevBtn: showPrevBtn, showNextBtn: showNextBtn})
            formState = updateObj(formState, {...formState, wizard: wizard})

            return updateObj(state, {...state, [id]: formState})


        case ACTIONS.focusControl:
            sections = state[id].sections
            sectionID = PAYLOAD.secID
            section = sections[sectionID]
            controls = section.controls
            controlName = PAYLOAD.name

            control = controls[controlName]
            control = updateObj(control, {size: 'lg'})

            controls = updateObj(controls, {...controls, [controlName]: control})

            section = updateObj(section, {...section, controls: controls})

            sections = updateObj(sections, {[sectionID]: section})

            formState = updateObj(formState, {...formState, sections: sections})

            return updateObj(state, {...state, [id]: formState})


        case ACTIONS.blurControl:
            sections = state[id].sections
            sectionID = PAYLOAD.secID
            section = sections[sectionID]
            controls = section.controls
            controlName = PAYLOAD.name

            control = controls[controlName]
            control = updateObj(control, {size: ' '})

            controls = updateObj(controls, {...controls, [controlName]: control})

            section = updateObj(section, {...section, controls: controls})

            sections = updateObj(sections, {[sectionID]: section})

            formState = updateObj(formState, {...formState, sections: sections})
            return updateObj(state, {...state, [id]: formState})

        case ACTIONS.neutraliseControl:
            sections = state[id].sections
            sectionID = PAYLOAD.secID
            section = sections[sectionID]
            controls = section.controls
            controlName = PAYLOAD.name

            control = controls[controlName]

            updatedValue = {isValid: null, isInvalid: null}
            updatedValue.showError = false

            control = updateObj(control, updatedValue)
            lf('control.....', control)

            controls = updateObj(controls, {...controls, [controlName]: control})
            lf('controls.....', controls)

            section = updateObj(section, {...section, controls: controls})
            lf('section.....', section)

            sections = updateObj(sections, {[sectionID]: section})
            lf('sections.....', sections)

            formState = updateObj(formState, {...formState, sections: sections})
            lf('formState.....', formState)

            return updateObj(state, {...state, [id]: formState})

        case ACTIONS.validateControl:
            isInvalid = null
            isValid = null
            sections = state[id].sections
            sectionID = PAYLOAD.secID
            section = sections[sectionID]
            controls = section.controls
            controlName = PAYLOAD.name

            control = controls[controlName]
            newValue = control.value

            switch (control.type) {
                case FORM_CONTROLS.email:
                    //@todo show entered email is inCorrect even when optional, but should not be inValid (as invalidation will disable wizard progress), maybe throw isIncorrect error?
                    isValid = isValidEmail(control.value)
                    isInvalid = control.isRequired && !isValid
                    break
                case FORM_CONTROLS.checkbox:
                case FORM_CONTROLS.switch:
                case FORM_CONTROLS.radio:
                    if (control.isRequired && newValue) {
                        isValid = true
                        isInvalid = false
                    } else if (control.isRequired) {
                        isValid = false
                        isInvalid = true
                    }
                    break
                default:
                    if (control.isRequired && newValue.length > 0) { // && newValue !== control.defaultValue
                        //@todo check for other kind of validations?
                        isValid = true
                        isInvalid = false
                    } else {
                        if (!control.isRequired) {
                            isValid = true
                            isInvalid = false
                        } else {
                            isValid = false
                            isInvalid = true
                        }
                    }
                    break
            }

            updatedValue = {isValid: isValid, isInvalid: isInvalid}
            updatedValue.showError = isInvalid

            control = updateObj(control, updatedValue)
            lf('control.....', control, control, isInvalid, isValid)

            controls = updateObj(controls, {...controls, [controlName]: control})
            lf('controls.....', controls)

            section = updateObj(section, {...section, controls: controls})
            lf('section.....', section)

            sections = updateObj(sections, {[sectionID]: section})
            lf('sections.....', sections)

            formState = updateObj(formState, {...formState, sections: sections})
            lf('formState.....', formState)

            return updateObj(state, {...state, [id]: formState})

        case ACTIONS.updateControlVal:
            sections = state[id].sections
            sectionID = PAYLOAD.secID
            section = sections[sectionID]
            controls = section.controls
            controlName = PAYLOAD.name

            newValue = PAYLOAD.value
            updatedValue = {value: newValue}
            updatedValue.hasChanged = (newValue !== control.defaultValue)

            control = controls[controlName]
            control = updateObj(control, updatedValue)

            controls = updateObj(controls, {...controls, [controlName]: control})

            section = updateObj(section, {...section, controls: controls})

            sections = updateObj(sections, {[sectionID]: section})

            formState = updateObj(formState, {...formState, sections: sections})
            return updateObj(state, {...state, [id]: formState})

        case ACTIONS.focusSection:
            currentForm = state.currentForm
            currentForm = updateObj(currentForm, {secID: PAYLOAD.secID})
            return updateObj(state, {...state, currentForm: currentForm})

        case ACTIONS.validateSection:
            isInvalid = null
            isValid = null

            id = state.currentForm.id
            formState = state[id]

            sections = formState.sections
            currentForm = state.currentForm

            sectionID = PAYLOAD.secID || currentForm.secID
            if (!sectionID) {
                sectionID = getObjKeys(formState.sections)[0]
            }

            section = sections[sectionID]
            controls = section.controls
            let invalidControlNames = []


            for (const [controlName, control] of Object.entries(controls)) {

                lf('control.......', control)
                if (control.isInvalid && !control.isValid) {
                    invalidControlNames.push(control.label)
                }
            }
            isValid = invalidControlNames.length === 0

            updatedValue = {
                isValid: isValid,
                errorHints: invalidControlNames,
            }


            section = updateObj(section, updatedValue)
            lf('section.....', section, invalidControlNames)

            sections = updateObj(sections, {[sectionID]: section})
            lf('sections.....', sections)

            formState = updateObj(formState, {...formState, sections: sections})
            lf('formState.....', formState)

            return updateObj(state, {...state, [id]: formState})


        case ACTIONS.showControl:
            control = updateObj(control, {isVisible: true})
            return updateObj(state, {...state, [controlName]: control})

        case ACTIONS.hideControl:
            control = updateObj(control, {isVisible: false})
            return updateObj(state, {...state, [controlName]: control})

        default:
            return state
    }
    //action.payload.postActionHandler !== null ? action.payload.postActionHandler() : ''
}

const getSectionControls = children => {

    lf('getSectionControls.....', children)
    let sectionControls = {}
    if (children !== undefined) {
        if (Array.isArray(children)) {
            children.map(child => {
                lf('mapping...', child)
                sectionControls = {...sectionControls, ...getSectionControls(child)}
            })
            lf('isArray', sectionControls)
        } else if (typeof children === "object" && isFormControl(children)) {
            sectionControls = {
                [children.props.name]: children.props
            }
            lf('isFormControl', sectionControls)
        } else if (typeof children !== "string" && hasProps(children)) {
            lf('RECURSIVE >>> >> >', children)
            sectionControls = getSectionControls(children.props.children)
        }
    }
    lf('sectionControls :::: ', sectionControls)

    return sectionControls
}

const isFormControl = control => {
    return FORM_CONTROLS[control.props.type] !== undefined
}

const hasProps = el => {
    return Object.keys(el.props).length > 0
}
