import {lf} from "../../DSYNR/Utils/debug";
import {FORM_CONTROLS, props_FControl, props_Form, props_FSection} from "../../DSYNR/Form/_state";
import {Col, Container, Row} from "react-bootstrap";
import {getControl_Calendar, getControl_Time} from "../../DSYNR/Form/_controls/_common";
import AControl from "../../DSYNR/Form/FormControl";
import {STORE} from "../../__STORE";
import AForm from "../../DSYNR/Form";
import React from "react";
import {APIS} from "../../DSYNR/Utils/wp.api";
import {useDispatch, useSelector} from "react-redux";
import {updateAppStatus} from "../../redux/actions/ACTIONS_APP";

export default function NewAppointment() {
    lf('NewAppointment')

    const dispatch = useDispatch()

    const token = useSelector(state => state.app.profile.token)
    const users = useSelector(state => state.app.users)
    const services = useSelector(state => state.app.services)

    const formID = 'appointment'
    const formPostURL = APIS.add

    //@todo if the select value isnt changed, the form data skips it. set default select value

    let userOptions = {}
    for (const userType in users) {
        const usrs = users[userType]
        for (const uid in usrs) {
            const usr = usrs[uid]
            const u = `${usr.fname} ${usr.lname} ${usr.email} ${usr.role}`
            userOptions[uid] = u
        }
    }

    let serviceOptions = {}
    for (const sid in services) {
        const srvc = services[sid]
        serviceOptions[sid] = srvc.name
    }

    let durationOptions = {}
    for (const sid in services) {
        const srvc = services[sid]
        const options = srvc.options
        for (const optionID in options) {
            const duration = options[optionID].duration.label
            const fee = options[optionID].fee
            durationOptions[optionID] = duration + ' ' + fee
        }
    }

    //@todo sync with google cal
    const initialDate = new Date().toDateString()

    //@todo sync with google cal, set times
    //@todo sync date of time with initialDate upon date change
    const initialTime = initialDate

    const formSections = {
        appointmentFor: props_FSection('appointmentFor',
            <Container>
                <Row>
                    <Col xs={12}>
                        <h3 className="fs-4 lead text-muted">Who is this appointment for?</h3>
                        <AControl secID='appointmentFor'
                                  formID={formID}
                                  {...props_FControl('form', FORM_CONTROLS.text, formID, '', '', '', '', '', false)} />

                        <AControl secID='appointmentFor'
                                  formID={formID}
                                  options={userOptions}
                                  {...props_FControl('user', FORM_CONTROLS.select)} />
                    </Col>
                </Row>
                <Row className="my-5">
                    <Col>
                        <h3 className="fs-4 lead text-muted">What is the appointment for?</h3>
                        <AControl secID='appointmentFor'
                                  formID={formID}
                                  options={serviceOptions}
                                  {...props_FControl('service', FORM_CONTROLS.select)} />
                    </Col>
                    <Col>
                        <h3 className="fs-4 lead text-muted">What's the session duration?</h3>
                        <AControl secID='appointmentFor'
                                  formID={formID}
                                  options={durationOptions}
                                  {...props_FControl('duration', FORM_CONTROLS.select)} />
                    </Col>
                </Row>
            </Container>
        ),

        appointmentWhen: props_FSection('appointmentWhen',
            <Container>
                <Row>
                    <Col>
                        <h3>Date</h3>
                        {getControl_Calendar(formID, 'appointmentWhen', 'date', initialDate)}
                    </Col>
                    <Col>
                        <h3>Time</h3>
                        {getControl_Time(formID, 'appointmentWhen', 'time', initialTime)}
                    </Col>
                </Row>
            </Container>
        ),

        appointmentSummary: props_FSection('appointmentSummary',
            <Container>
                <Row>appointmentSummary</Row>
            </Container>)
    }

    const formSubmissionSuccessful = () => {
        lf('formSubmissionSuccessful', STORE.getState().forms)
        // alert('formSubmissionSuccessful')
    }

    const formSubmissionError = () => {
        lf('formSubmissionError', STORE.getState().forms)
        dispatch(updateAppStatus('onSubmitError...'))
    }

    return <AForm
        postURL={formPostURL}
        onSubmitSuccess={formSubmissionSuccessful}
        onSubmitError={formSubmissionError}
        {...props_Form(formID, formSections, token, '', '', 'Add Appointment')}
    />
}
