import {lf} from "../Utils/debug";
import React from "react";
import {Badge} from "react-bootstrap";

/**
 *
 * @param tip
 * @param type control|section|form
 * @returns {JSX.Element}
 * @constructor
 */
export default function ErrorTip({tip = '', type = 'control', hints = []}) {
    lf('ErrorTip', tip, type, hints)

    let theHints = <></>
    if (hints.length > 0) {
        theHints = <>
            <br/>
            <code className="text-black-50">Check:</code>
            {theHints = hints.map((hint, index) => {
                return <Badge pill key={index} variant="light" className="mr-1">{hint}</Badge>
            })}
        </>
    }

    switch (type) {
        case "form":
            return <p className="bg-secondary text-white p-3 rounded-lg">{tip}</p>
        case "section":
            return <p className="bg-warning text-monospace text-white p-2 rounded-lg">
                {tip}
                {theHints}
            </p>
        default:
            return <span className="bg-danger text-white p-1 rounded-lg">{tip}</span>
    }
}
