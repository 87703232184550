import {lf} from "../DSYNR/Utils/debug";
import {addNew} from "../redux/actions/ACTIONS_APP";
import {FaPlus} from "react-icons/all";
import {Button} from "react-bootstrap";
import React from "react";
import {useDispatch} from "react-redux";

export default function AddNewBtn({what, cls = ''}) {
    lf('AddNewBtn')

    const dispatch = useDispatch()
    const className = 'shadow-sm text-white ' + cls

    return <Button size={"lg"} variant={"info"} className={className}
                   onClick={() => {
                       dispatch(addNew(what))
                   }}>
        <FaPlus/>
    </Button>
}
