import {lf} from "../../Utils/debug";
import {useDispatch} from "react-redux";
import {Form} from "react-bootstrap";
import {updateControlVal} from "../_actions";
import React from "react";

export default function Check(controlProps) {
    lf('Check', controlProps)
    const dispatch = useDispatch()
    return <Form.Check
        name={controlProps.name}
        id={controlProps.name}
        type={controlProps.type}
        label={controlProps.label}
        required={controlProps.isRequired}
        isValid={controlProps.isValid}
        isInvalid={controlProps.isInvalid}
        checked={controlProps.value}
        custom
        onFocus={controlProps.focusControl}
        onBlur={controlProps.blurControl}
        onChange={(event) => {
            dispatch(updateControlVal(controlProps.name, event.currentTarget.checked, controlProps.secID, controlProps.formID))
        }}
    />
}
