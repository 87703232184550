import React from "react";
import {connect} from "react-redux";
import {lf} from "../Utils/debug";
import {
    blurForm,
    focusForm,
    registerForm,
    validateForm,
    submitForm,
    gotoNextWizardStep, gotoPreviousWizardStep, updateWizardControls, validateSection,
} from "./_actions";

//NOTICE TO KEEP THE NAMING (& PATH) CONVENTION IN THE PARENT APP WHICH IS USING FORM
import {STORE} from "../../__STORE";

import {getObjKeys} from "../Utils/obj";
import FormWizard from "./FormWizard";
import _Form from "./Form";

/**
 * Bootstrap Form
 */
function AForm(props) {

    lf('AForm', props)

    const id = props.id
    const sections = props.sections
    const sectionIDs = getObjKeys(sections)

    //sections{} has only one section
    let body = sections[sectionIDs[0]].body
    if (sectionIDs.length > 1)
        body = <FormWizard {...props}/>


    if (!STORE.getState().forms[id]) {
        props.registerForm()

        //USING props.updateWizardControls(props) to hide PrevBtn for first slide upon initial load throws error
        //props.updateWizardControls(props)
    }

    return _Form(props, body);
}

const mapStateToProps = (state, ownProps) => {
    let updatedState = {}
    let formProps = state.forms[ownProps.id]
    if (formProps) {
        for (const [prop, value] of Object.entries(formProps)) {
            updatedState[prop] = value
        }
        ownProps = updatedState
    }
    return ownProps
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const id = ownProps.id
    const token = ownProps.token
    return {
        registerForm: () => dispatch(registerForm(ownProps)),
        focusForm: () => dispatch(focusForm(id)),
        blurForm: () => dispatch(blurForm(id)),
        submitForm: (formState) => {
            dispatch(submitForm(formState, token))
        },
        gotoNextWizardStep: () => {
            lf('validateSection ownProps..........', ownProps)
            dispatch(validateSection())
            dispatch(gotoNextWizardStep(id))
        },
        gotoPreviousWizardStep: () => dispatch(gotoPreviousWizardStep(id)),
        updateWizardControls: (props) => dispatch(updateWizardControls(id, props)),
        validateForm: () => dispatch(validateForm(id)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AForm)
