import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {STORE} from "./__STORE";
import App from "./screens/app";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={STORE}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('app')
);
