import {lf} from "../../Utils/debug";
import {useDispatch} from "react-redux";
import {Form, FormLabel, InputGroup} from "react-bootstrap";
import {updateControlVal} from "../_actions";
import React from "react";

export default function Input(controlProps) {
    lf('Input', controlProps)
    const dispatch = useDispatch()

    const IFC = <Form.Control name={controlProps.name}
                              type={controlProps.type}
                              required={controlProps.isRequired}
                              isValid={controlProps.isValid}
                              isInvalid={controlProps.isInvalid}
                              value={controlProps.value}
                              placeholder={controlProps.placeholder}
                              size={controlProps.size}
                              plaintext={controlProps.isPlaintext}
                              readOnly={controlProps.isReadOnly}
                              onFocus={controlProps.focusControl}
                              onBlur={controlProps.blurControl}
                              onChange={(event) => {
                                  dispatch(updateControlVal(controlProps.name, event.target.value, controlProps.secID, controlProps.formID))
                              }}
    />

    return <>{
        controlProps.conjoinLabel ? (<InputGroup><InputGroup.Text>{controlProps.label}</InputGroup.Text>{IFC}</InputGroup>) : IFC
    }</>
}
