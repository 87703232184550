import {lf} from "../DSYNR/Utils/debug";
import {useSelector} from "react-redux";

export default function AProgressBar() {
    lf('AProgressBar')
    const reqProgress = useSelector(state => state.app.reqProgress)
    return <div className="progressBarRoot position-absolute bottom left w-100 bg-white">
        <div className="progressBar position-absolute left w-100 h-100" style={{clip: `rect(0, ${reqProgress}vw, 3px, 0)`}}/>
    </div>
}
