import './app.scss'

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {lf} from "../../DSYNR/Utils/debug";
import AppMain from "./AppMain";
import {getLocallyStoredData} from "../../DSYNR/Utils/storage";
import {enableBackgroundServerUpdates, fdFetched, fetchAPI, fetchedPref, iniFetched, recordsFetched, set, update, updateAppStatus} from "../../redux/actions/ACTIONS_APP";
import SignInScreen from "../SignInScreen";

export default function App() {

    lf('App');

    const dispatch = useDispatch()

    const isLogged = useSelector(state => state.app.profile.isLogged)

    const updateFrequency = useSelector(state => state.app.pref.updateFrequency) * 60000
    const isUpdating = useSelector(state => state.app.updates.isUpdating)
    const token = useSelector(state => state.app.profile.token)
    const appDataLoaded = useSelector(state => state.app.app.dataLoaded)

    if (isLogged) {
        if (!appDataLoaded) {
            const onSubmitError = () => {
                lf('onSubmitError.........................')
                dispatch(updateAppStatus('onSubmitError...'))
            }

            // dispatch(fetchAPI('profile', token, (response) => {
            //     dispatch(update('profile',response.data))
            // }, onSubmitError))
            // dispatch(fetchAPI('pref', token, (response) => {
            //     dispatch(update('pref',response.data))
            //     dispatch(set('currentScreen', response.data.defaultScreen))
            // }, onSubmitError))


            dispatch(fetchAPI('ini', token, (response) => {

                dispatch(iniFetched(response.data))

                dispatch(fetchAPI('fd', token, (response) => {
                    dispatch(fdFetched(response.data))

                    dispatch(fetchAPI('users', token, (response) => {

                        dispatch(updateAppStatus('App Data Loaded. Ready for you!'))

                        let data = response.data
                        data.recordType = 'user'
                        dispatch(recordsFetched(data))
                        dispatch(update('app', {dataLoaded: true}))
                        dispatch(enableBackgroundServerUpdates(updateFrequency, isUpdating, token))
                    }, onSubmitError))
                }, onSubmitError))
            }, onSubmitError))


        }
    } else {
        let profile = getLocallyStoredData('p', true)
        if (profile !== null) {
            profile.isLogged = true
            dispatch(update('profile', profile))
        }
    }

    return <>
        {isLogged ? <AppMain/> : <SignInScreen/>}
    </>
}
