export const ACTIONS = {
    SET: 'SET',
    update: 'update',
    exitCurrentScreen: 'exitCurrentScreen',
    updateFutureAppointments: 'updateFutureAppointments',
    updateScreenBody: 'updateScreenBody',
    loadScreen: 'loadScreen',
    changeScreen: 'changeScreen',
    viewItem: 'viewItem',
    saveItem: 'saveItem',
    apiFetched: 'apiFetched',
    iniFetched: 'iniFetched',
    fetchAPI: 'fetchAPI',
    showModal: 'showModal',
    hideModal: 'hideModal',
    showNotificationPanel: 'showNotificationPanel',
    hideNotificationPanel: 'hideNotificationPanel',
    clearAllNotifications: 'clearAllNotifications',

}
