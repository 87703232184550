import AForm from "../DSYNR/Form";
import React from "react";
import {lf} from "../DSYNR/Utils/debug";
import {APPDATA, STORE} from "../__STORE";
import {storeLocally} from "../DSYNR/Utils/storage";
import {FORM_CONTROLS, props_Form, props_FSection} from "../DSYNR/Form/_state";
import {getControl_Text} from "../DSYNR/Form/_controls/_common";
import {set} from "../redux/actions/ACTIONS_APP";
import {useDispatch, useSelector} from "react-redux";
import {VscSearch} from "react-icons/all";
import {APIS} from "../DSYNR/Utils/wp.api";

export default function SearchForm() {
    lf('SearchForm')

    const dispatch = useDispatch()

    const token = useSelector(state => state.app.profile.token)

    const formID = 'SearchForm'
    const formPostURL = APIS.search

    const header = <h3 className="fw-lighter display-6 opacity-75 text-secondary mb-4">
        <span className="text-secondary opacity-25">{}</span> Quick Find
    </h3>

    const formSections = {
        default: props_FSection('default',
            <>
                {getControl_Text(formID, 'default', 'search', <VscSearch/>, '', false, 'Search For...', '', '', true, FORM_CONTROLS.text, true, true)}
            </>
        )
    }

    const submissionSuccessful = () => {
        const forms = STORE.getState().forms
        const curFormID = forms['currentForm'].id
        const curForm = forms[curFormID]
        const curFormResponse = curForm.response

        lf('submissionSuccessful for ' + curFormID, curFormResponse)

        const responseJSON = curFormResponse.json
        const responseJSONData = responseJSON.data

        if (responseJSON.success) {
        }
    }

    const submissionError = () => {
        lf('submissionError', STORE.getState().forms)
        //@todo error state....?
    }

    return <AForm
        postURL={formPostURL}
        onSubmitSuccess={submissionSuccessful}
        onSubmitError={submissionError}
        {...props_Form(formID, formSections, token, header, '', '', false, false,true)}
    />
}
