import React from "react";
import {lf} from "../Utils/debug";
import {FormSection} from "./FormSection";
import {getObjKeys, updateObj} from "../Utils/obj";

export function FormWizardSlides(props) {
    lf('FormWizardSlides', props)

    return getObjKeys(props.sections).map((groupID, index) => {
        props = updateObj(props, {index: index})
        props = updateObj(props, {formSection: props.sections[groupID]})
        return FormSection(props)
    });
}
